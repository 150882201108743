<template>
  <div class="row page-wrapper">
    <div class="col-xs-12 page-header">
      <h1>Our <small>Fleet</small></h1>
    </div>

    <div class="col-xs-12">
      <div class="filter">
        <button class="btn" value="all" @click="filterItems('all')">All</button>
        <button class="btn" value="filter-cars" @click="filterItems('cars')">
          Cars
        </button>
        <button class="btn" value="filter-buses" @click="filterItems('buses')">
          Buses
        </button>
        <button class="btn" value="filter-vans" @click="filterItems('vans')">
          Vans
        </button>
        <button class="btn" value="filter-suvs" @click="filterItems('suvs')">
          SUVs
        </button>
        <button class="btn" value="filter-limo" @click="filterItems('limo')">
          Limousines
        </button>
      </div>
    </div>

    <div id="vehicles-grid" class="col-xs-12">
      <div v-if="selected == 'all' || selected == 'cars'">
        <div class="row">
          <div class="col-xs-12 col-md-4 pull-left vehicle filter-cars">
            <div class="item-grid">
              <a
                id="lincoln-towncar"
                class="boxer"
                href="/img/fleet/gallery/cars/thumbs/lincoln-towncar.jpg"
                title="Lincoln Town Car Sedan"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/cars/thumbs/lincoln-towncar.jpg"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">Lincoln Town Car Sedan</h1>
                <ul>
                  <li>Seating up to 4 Passengers with Limited Luggage</li>
                  <li>Tinted Windows</li>
                  <li>Individual Climate Control</li>
                  <li>Great for Private Hires and Airport Pickups</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>

          <div class="col-xs-12 col-md-4 pull-left vehicle filter-cars">
            <div class="item-grid">
              <a
                id="merc-s550"
                class="boxer"
                href="/img/fleet/gallery/cars/thumbs/merc-s550.jpg"
                title="Mercedes Benz S550"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/cars/thumbs/merc-s550.jpg"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">Mercedes Benz S550</h1>
                <ul>
                  <li>Seating up to 4 Passengers with Limited Luggage</li>
                  <li>Tinted Windows</li>
                  <li>Individual Climate Control</li>
                  <li>Great for Private Hires and Airport Pickups</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>
        </div>
        <!-- end.row -->
      </div>

      <div v-if="selected == 'all' || selected == 'vans'">
        <div class="row">
          <div class="col-xs-12 col-md-4 pull-left vehicle filter-vans">
            <div class="item-grid">
              <a
                id="merc-sprinter"
                class="boxer"
                href="/img/fleet/gallery/vans/thumbs/merc-sprinter.jpg?v=85603"
                title="Mercedes Sprinter"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/vans/thumbs/merc-sprinter.jpg?v=85603"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">Mercedes Sprinter</h1>
                <ul>
                  <li>Seating up to 15 Passengers</li>
                  <li>Tinted Windows</li>
                  <li>DVD Entertainment System</li>
                  <li>Great for Large Parties and Prom Night</li>
                  <li>It's a Party on Wheels</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>

          <div class="col-xs-12 col-md-4 pull-left vehicle filter-vans">
            <div class="item-grid">
              <a
                id="15-passenger-vans"
                class="boxer"
                href="/img/fleet/gallery/buses/thumbs/15-passenger-vans.jpg?v=47563"
                title="15 Passenger Vans"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/buses/thumbs/15-passenger-vans.jpg?v=47563"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">15 Passenger Vans</h1>
                <ul>
                  <li>Seating up to 15 Passengers with Limited Luggage</li>
                  <li>Tinted Windows</li>
                  <li>Economical and Easy to Navigate</li>
                  <li>Great for Day Trips and Short Hires</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>

          <div class="col-xs-12 col-md-4 pull-left vehicle filter-vans">
            <div class="item-grid">
              <a
                id="15-passenger-ex-coach"
                class="boxer"
                href="/img/fleet/gallery/buses/thumbs/15-passenger-ex-coach.jpg"
                title="15 Passenger Executive Coach"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/buses/thumbs/15-passenger-ex-coach.jpg"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">15 Passenger Executive Coach</h1>
                <ul>
                  <li>Seating up to 15 Passengers with Limited Luggage</li>
                  <li>Tinted Windows</li>
                  <li>PA/DVD Entertainment Systems</li>
                  <li>Individual Leather Seats</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>
        </div>
        <!-- end.row -->
      </div>

      <div v-if="selected == 'all' || selected == 'buses'">
        <div class="row">
          <div class="col-xs-12 col-md-4 pull-left vehicle filter-buses">
            <div class="item-grid">
              <a
                id="15-passenger-minibus"
                class="boxer"
                href="/img/fleet/gallery/buses/thumbs/15-passenger-minibus.jpg?v=63901"
                title="15 Passenger Minibuses"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/buses/thumbs/15-passenger-minibus.jpg?v=63901"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">15 Passenger Minibuses</h1>
                <ul>
                  <li>Seating up to 15 Passengers with Limited Luggage</li>
                  <li>Tinted Windows</li>
                  <li>PA/DVD Entertainment Systems</li>
                  <li>Wheelchair Accessible Vehicles Available</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>

          <div class="col-xs-12 col-md-4 pull-left vehicle filter-buses">
            <div class="item-grid">
              <a
                id="25-passenger-minibus"
                class="boxer"
                href="/img/fleet/gallery/buses/thumbs/25-passenger-minibus.jpg"
                title="25 Passenger Minibuses"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/buses/thumbs/25-passenger-minibus.jpg"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">25 Passenger Minibuses</h1>
                <ul>
                  <li>Great Travel Option for Medium Sized Parties</li>
                  <li>Tinted Windows</li>
                  <li>A/C and Centralized Heat</li>
                  <li>Comfortable Reclining Seats Throughout the Bus</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>

          <div class="col-xs-12 col-md-4 pull-left vehicle filter-buses">
            <div class="item-grid">
              <a
                id="30-passenger-minibus"
                class="boxer"
                href="/img/fleet/gallery/buses/thumbs/30-passenger-minibus.jpg?v=13594"
                title="30 Passenger Minibuses"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/buses/thumbs/30-passenger-minibus.jpg?v=13594"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">30 Passenger Minibuses</h1>
                <ul>
                  <li>Seating up to 30 Passengers with Luggage</li>
                  <li>Tinted Windows</li>
                  <li>High Back Reclining Seats</li>
                  <li>PA/DVD Entertainment Systems</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>
        </div>
        <!-- end.row -->

        <div class="row">
          <div class="col-xs-12 col-md-4 pull-left vehicle filter-buses">
            <div class="item-grid">
              <a
                id="33-passenger-minibus"
                class="boxer"
                href="/img/fleet/gallery/buses/thumbs/33-passenger-minibus.jpg"
                title="33 Passenger Minibuses"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/buses/thumbs/33-passenger-minibus.jpg"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">33 Passenger Minibuses</h1>
                <ul>
                  <li>Seating up to 33 Passengers with Luggage</li>
                  <li>Tinted Windows</li>
                  <li>High Back Reclining Seats</li>
                  <li>PA/DVD Entertainment Systems</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>

          <div class="col-xs-12 col-md-4 pull-left vehicle filter-buses">
            <div class="item-grid">
              <a
                id="57-passenger-coach-bus"
                class="boxer"
                href="/img/fleet/gallery/buses/thumbs/57-passenger-coach-bus.jpg?v=23968"
                title="57 Passenger Coach Bus"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/buses/thumbs/57-passenger-coach-bus.jpg?v=23968"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">57 Passenger Coach Bus</h1>
                <ul>
                  <li>Seating to 57 Passengers with Luggage</li>
                  <li>Tinted Windows</li>
                  <li>High Back Reclining Seats</li>
                  <li>PA/DVD Entertainment Systems</li>
                  <li>On-board Latrine Services</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>
        </div>
      </div>

      <div v-if="selected == 'all' || selected == 'limo'">
        <div class="row">
          <div class="col-xs-12 col-md-4 pull-left vehicle filter-limo">
            <div class="item-grid">
              <a
                id="hummer-h2"
                class="boxer"
                href="/img/fleet/gallery/limos/thumbs/hummer-h2.jpg"
                title="Hummer H2 Stretch Limo"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/limos/thumbs/hummer-h2.jpg"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">Hummer H2 Stretch Limo</h1>
                <ul>
                  <li>Seating up to 18 Passengers with Limited Luggage</li>
                  <li>Tinted Windows</li>
                  <li>DVD Entertainment System</li>
                  <li>Great for Large Parties</li>
                  <li>The Perfect Limo for a Night-out in Style</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>

          <div class="col-xs-12 col-md-4 pull-left vehicle filter-limo">
            <div class="item-grid">
              <a
                id="lincoln-stretch-limo"
                class="boxer"
                href="/img/fleet/gallery/limos/thumbs/lincoln-stretch-limo.jpg"
                title="Lincoln Stretch Limo"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/limos/thumbs/lincoln-stretch-limo.jpg"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">Lincoln Stretch Limo</h1>
                <ul>
                  <li>Seating up to 10 Passengers with Limited Luggage</li>
                  <li>Tinted Windows</li>
                  <li>DVD Entertainment System</li>
                  <li>Great for Private Hires and Tours</li>
                  <li>The Quintessential Limousine Experience</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>
        </div>
      </div>

      <div v-if="selected == 'all' || selected == 'suvs'">
        <div class="row">
          <div class="col-xs-12 col-md-4 pull-left vehicle filter-suvs">
            <div class="item-grid">
              <a
                id="cadillac-escalade"
                class="boxer"
                href="/img/fleet/gallery/suvs/thumbs/escalade.jpg"
                title="Cadillac Escalade"
                data-gallery="gallery"
                ><img
                  class="img-responsive"
                  src="/img/fleet/gallery/suvs/thumbs/escalade.jpg"
                  alt=""
              /></a>

              <div class="inner-card">
                <h1 class="title h4">Cadillac Escalade</h1>
                <ul>
                  <li>Seating up to 7 Passengers with Luggage</li>
                  <li>Tinted Windows</li>
                  <li>Individual Climate Control</li>
                  <li>DVD Entertainment System</li>
                  <li>Great for Private Hires and Airport Pickups</li>
                </ul>
              </div>
              <!-- end.inner-card -->
            </div>
            <!-- end.item-grid -->
          </div>
        </div>
      </div>

      <br />
    </div>
    <!-- end.item-grid-wrapper -->
  </div>
  <!-- end.page-wrapper -->
</template>

<script>
export default {
  name: "Vehicles",

  data() {
    return {
      selected: "all",
    };
  },

  methods: {
    filterItems(selectedType) {
      this.selected = selectedType;
    },
  },
};
</script>
