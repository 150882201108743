<template>
  <header>
    <div class="row">
      <div class="col-md-12">
        <a class="inline pull-left" href="/"
          ><img class="img-responsive" id="logo" src="/img/logo.png" alt=""
        /></a>
        <div id="header-contact" class="pull-right text-right">
          <p class="nogap-bottom">
            <a
              class="social-media pull-left"
              href="https://www.facebook.com/pages/Ramjay-Inc/183901098299069"
              target="_blank"
              ><i class="fa fa-facebook-square"></i
            ></a>
            &nbsp;
            <a class="tel" href="tel:703-567-6272">703-567-6272</a>
          </p>
        </div>
      </div>
    </div>
    <!-- end.row -->

    <div class="row">
      <div class="navbar navbar-default hidden-xs" role="navigation">
        <div class="container">
          <div class="navbar-collapse collapse">
            <ul class="nav navbar-nav">
              <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/about">About</router-link></li>
              <li><router-link to="/services">Services</router-link></li>
              <li><router-link to="/vehicles">Vehicles</router-link></li>
              <li><router-link to="/contact-us">Contact Us</router-link></li>
            </ul>
          </div>
          <!-- end.nav-collapse -->
        </div>
        <!-- end.container -->
      </div>
      <!-- end.navbar-default -->
    </div>
    <!-- end.row -->
  </header>

  <router-view />

  <div id="sub-footer" class="row">
    <div class="col-md-3 hidden-xs hidden-sm">
      <h3>Quick Links</h3>

      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/about">About</router-link></li>
        <li><router-link to="/services">Services</router-link></li>
        <li><router-link to="/vehicles">Vehicles</router-link></li>
        <li><router-link to="/contact-us">Contact Us</router-link></li>
      </ul>
    </div>

    <div class="col-sm-6 col-md-3">
      <h3>We Are Here</h3>

      <div itemscope itemtype="http://schema.org/PostalAddress">
        <address>
          <span itemprop="name">VA HQ:</span><br />
          <span itemprop="postOfficeBoxNumber">5285 Shawnee Road</span><br />
          <span itemprop="addressLocality">Suite 315</span><br />
          <span itemprop="addressRegion">Alexandria, VA</span><br />
          <span itemprop="postalCode">22312</span>
        </address>
        <p>
          <a href="https://maps.app.goo.gl/p2tSRhaNNn3Y1b5GA" target="_blank"
            >[Get Directions]</a
          >
        </p>
      </div>
    </div>

    <div class="col-sm-6 col-md-3">
      <h3>Contact Us</h3>

      <p class="nogap-bottom"><label>Phone:</label></p>
      <p><a href="tel:703-567-6272">703-567-6272</a></p>

      <p class="nogap-bottom"><label>Fax:</label></p>
      <p><a href="tel:703-373-7296">703-373-7296</a></p>

      <p class="nogap-bottom"><label>E-mail:</label></p>
      <p><a href="mailto:info@ramjayinc.com">info@ramjayinc.com</a></p>
    </div>

    <div class="col-xs-12 col-md-3">
      <h3>SSI Protection</h3>

      <p>
        <a
          id="ssi-logo"
          class="imglink"
          href="http://shasthra.us/"
          target="_blank"
        ></a>
      </p>
      <p>
        The highest level of protection under the law and protect them against
        unjust litigation.
      </p>
      <a href="http://shasthra.us/" target="_blank">[SSI Protection]</a>
    </div>
  </div>

  <footer class="row">
    <p id="copyright" class="text-center">
      Copyright &copy; {{ new Date().getFullYear() }} Ramjay Inc.
    </p>
    <p id="devs" class="text-center">
      Made with <span class="glyphicon glyphicon-heart"></span> by
      <a href="http://olionsoft.com.lk/" target="_blank">Olionsoft</a>
    </p>
  </footer>
</template>
