<template>
  <div class="row">
    <div
      id="hero-carousel"
      class="carousel slide carousel-fade"
      data-ride="carousel"
      data-interval="5000"
    >
      <!-- Indicators -->
      <ol class="carousel-indicators hidden-xs">
        <li data-target="#hero-carousel" data-slide-to="0" class="active"></li>
        <li data-target="#hero-carousel" data-slide-to="1"></li>
        <li data-target="#hero-carousel" data-slide-to="2"></li>
        <li data-target="#hero-carousel" data-slide-to="3"></li>
        <li data-target="#hero-carousel" data-slide-to="4"></li>
        <li data-target="#hero-carousel" data-slide-to="5"></li>
        <li data-target="#hero-carousel" data-slide-to="6"></li>
        <li data-target="#hero-carousel" data-slide-to="7"></li>
      </ol>

      <div class="carousel-inner">
        <div class="item active">
          <img
            class="img-responsive"
            src="/img/slider/slider-img_1.jpg"
            alt=""
          />
        </div>

        <div class="item">
          <img
            class="img-responsive"
            src="/img/slider/slider-img_2.jpg"
            alt=""
          />
        </div>

        <div class="item">
          <img
            class="img-responsive"
            src="/img/slider/slider-img_3.jpg"
            alt=""
          />
        </div>

        <div class="item">
          <img
            class="img-responsive"
            src="/img/slider/slider-img_4.jpg"
            alt=""
          />
        </div>

        <div class="item">
          <img
            class="img-responsive"
            src="/img/slider/slider-img_5.jpg"
            alt=""
          />
        </div>

        <div class="item">
          <img
            class="img-responsive"
            src="/img/slider/slider-img_6.jpg"
            alt=""
          />
        </div>

        <div class="item">
          <img
            class="img-responsive"
            src="/img/slider/slider-img_7.jpg"
            alt=""
          />
        </div>

        <div class="item">
          <img
            class="img-responsive"
            src="/img/slider/slider-img_8.jpg"
            alt=""
          />
        </div>
      </div>

      <!-- Controls -->
      <a
        class="left carousel-control visible-xs"
        href="#hero-carousel"
        role="button"
        data-slide="prev"
      >
        <span class="glyphicon glyphicon-chevron-left"></span>
      </a>

      <a
        class="right carousel-control visible-xs"
        href="#hero-carousel"
        role="button"
        data-slide="next"
      >
        <span class="glyphicon glyphicon-chevron-right"></span>
      </a>
    </div>
  </div>
  <!-- end.row -->

  <div id="section-services" class="row section">
    <div class="col-sm-6">
      <div class="pull-left feat-services">
        <div class="col-md-2">
          <img class="fa-wrapper" src="/svg/fa-bus.svg" alt="" />
        </div>
        <div class="col-md-10">
          <h4>Shuttle</h4>
          <p>
            Who says you can’t have it all? Treat your community to the most
            ideal commute by choosing Ramjay Inc. as your shuttle service
            provider and let heed the woes of rush hour! We proudly serve the
            Greater Washington Metropolitan area with prompt, professional and
            reliable service, of which we are best known for.
          </p>
        </div>
      </div>

      <div class="pull-left feat-services">
        <div class="col-md-2">
          <img class="fa-wrapper" src="/svg/fa-car.svg" alt="" />
        </div>
        <div class="col-md-10">
          <h4>Day Trips &amp; Hires</h4>
          <p>
            Need a car or bus to go on vacation? Call Ramjay and we will make
            your trip an unforgettable experience.
          </p>
        </div>
      </div>

      <div class="pull-left feat-services">
        <div class="col-md-2">
          <img class="fa-wrapper" src="/svg/fa-building.svg" alt="" />
        </div>
        <div class="col-md-10">
          <h4>Corporate</h4>
          <p>
            Does your company need to secure safe and reliable transportation
            for its employees? We can help! Ramjay provides exclusive
            transportation services to our corporate clients, including sedan,
            limo, and shuttle services.
          </p>
        </div>
      </div>

      <div class="pull-left feat-services">
        <div class="col-md-2">
          <img class="fa-wrapper" src="/svg/fa-plane.svg" alt="" />
        </div>
        <div class="col-md-10">
          <h4>Airport Transportation</h4>
          <p>
            Got a flight to catch? You can count on us to get you there on time.
          </p>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="pull-left feat-services">
        <div class="col-md-2">
          <img class="fa-wrapper" src="/svg/fa-birthday-cake.svg" alt="" />
        </div>
        <div class="col-md-10">
          <h4>Wedding &amp; Leisure</h4>
          <p>
            Want to make your special day unforgettable? We at Ramjay are
            committed to giving you the utmost in service to make your special
            day a memory that will last a lifetime.
          </p>
        </div>
      </div>

      <div class="pull-left feat-services">
        <div class="col-md-2">
          <img class="fa-wrapper" src="/svg/fa-map-marker.svg" alt="" />
        </div>
        <div class="col-md-10">
          <h4>Tours &amp; Sightseeing</h4>
          <p>
            Our trained travel specialists will develop and implement the
            logistical planning to move your group effortlessly to and from your
            event/place of interest.
          </p>
        </div>
      </div>

      <div class="pull-left feat-services">
        <div class="col-md-2">
          <img class="fa-wrapper" src="/svg/fa-users.svg" alt="" />
        </div>
        <div class="col-md-10">
          <h4>Convention &amp; Groups</h4>
          <p>
            Washington DC is one of the top destinations for conventions and
            groups. Ramjay specializes in making your function perfect. We will
            take care of all the logistics, be it picking the guests from the
            airport, taking them to the convention/conference and even taking
            them around town.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- end.row -->

  <div id="section-fleet" class="row section">
    <div class="col-sm-3 col-md-3 text-center grid-fleet">
      <router-link class="block" to="/vehicles">
        <img
          class="center-block img-responsive img-circle"
          src="/img/fleet/fleet-suvs.jpg"
          alt=""
        />
      </router-link>
      <router-link to="/vehicles" class="link">SUVs</router-link>
    </div>

    <div class="col-sm-3 col-md-3 text-center grid-fleet">
      <router-link class="block" to="/vehicles">
        <img
          class="center-block img-responsive img-circle"
          src="/img/fleet/fleet-limos.jpg"
          alt=""
        />
      </router-link>
      <router-link to="/vehicles" class="link">Limousines</router-link>
    </div>

    <div class="col-sm-3 col-md-3 text-center grid-fleet">
      <router-link class="block" to="/vehicles">
        <img
          class="center-block img-responsive img-circle"
          src="/img/fleet/fleet-cars.jpg"
          alt=""
        />
      </router-link>
      <router-link to="/vehicles" class="link">Luxury Cars</router-link>
    </div>

    <div class="col-sm-3 col-md-3 text-center grid-fleet nogap-bottom">
      <router-link class="block" to="/vehicles">
        <img
          class="center-block img-responsive img-circle"
          src="/img/fleet/fleet-buses.jpg"
          alt=""
        />
      </router-link>
      <router-link to="/vehicles" class="link">Vans &amp; Buses</router-link>
    </div>
  </div>
  <!-- end.row -->
</template>

<script>
export default {
  name: "Home",
};
</script>
