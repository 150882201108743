<template>
  <div class="row page-wrapper">
    <div class="col-xs-12 page-header">
      <h1 class="text-center">404 - Page Not Found</h1>
    </div>

    <div class="col-md-8 col-md-offset-2">
      <p>Page not found.</p>

      <br />
    </div>

    <br class="visible-xs visible-sm visible-md" />
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
